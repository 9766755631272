/* eslint-disable no-undef */
<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button v-if="activeStep > 0" type="warning" @click="prevStep" icon="el-icon-arrow-left">Өмнөх</el-button>
          <el-button v-if="activeStep < 2" :disabled="isButtonDisabled" :type="isButtonDisabled ? 'disabled' : 'primary'" :loading="isButtonLoading" @click="nextStep">Дараах <i class="el-icon-arrow-right el-icon-right"></i></el-button>
          <el-button v-if="activeStep===2" :disabled="isDisabledCart" :type="isDisabledCart ? 'disabled' : 'success'" :loading="isButtonLoading" @click="saveOrder()">Захиалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form label-position="top" :model="orderFormData" ref="orderFormData" :rules="orderFormRules">
      <el-row>
        <el-col :span="20" :offset="2">
          <custom-header title="Захиалга үүсгэх" :isBackable="false"/>
          <el-steps :space="200" :active="activeStep" finish-status="success" align-center  style="justify-content: center" class="mb20">
            <el-step title="Захиалагч"></el-step>
            <el-step title="Хүргэлтийн хаяг"></el-step>
            <el-step title="Бүтээгдэхүүн"></el-step>
          </el-steps>
          <el-row :gutter="20">
            <el-col :span="activeStep === 0 ? 24 : 16">
              <div class="panel" v-if="activeStep === 0">
                <div class="panel-item">
                  <header>Захиалагч</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Утас" prop="user_phone">
                        <el-input v-model="orderFormData.user_phone" type="number" placeholder="Утасны дугаар"></el-input>
                      </el-form-item>
                      <el-form-item label="Email" prop="user_email">
                        <el-input v-model="orderFormData.user_email" type="email" placeholder="И-мэйл хаяг"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Харилцагчийн төрөл" prop="vat_type">
                        <el-select placeholder="Хувь хүн" v-model="orderFormData.vat_type">
                          <el-option
                            v-for="(type, vatIndex) in vatTypes"
                            :key="vatIndex"
                            :label="type.label"
                            :value="type.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-row :gutter="20" v-if="orderFormData.vat_type === 3">
                        <el-col :span="12">
                          <el-form-item label="Регистрийн дугаар" prop="vat_regno">
                            <el-input v-model="orderFormData.vat_regno" placeholder="6183352" @change="numberValidate()"/>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item label="Байгууллага">
                            <el-input v-loading="isLoadingEbarimt" disabled placeholder="ТОК ТОК ХХК" v-model="orderFormData.vat_company_name" />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="panel" v-if="activeStep === 1">
                <div class="panel-item">
                  <header>Хүргэлтийн хаяг</header>
                  <el-form-item label="Хаяг">
                    <el-select
                      style="width: 100%"
                      v-model="orderFormData.full_address"
                      filterable
                      remote
                      @change="onChangeAddress"
                      placeholder="ХУД 3-р хороо Гоёо"
                      :remote-method="filterAddress"
                      :loading="addressLoading">
                      <el-option
                        v-for="(item, addressIndex) in suggestedAddresses"
                        :key="addressIndex"
                        :label="item.full_address"
                        :value="item.full_address">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-form-item label="Дүүрэг" prop="district">
                        <el-select v-model="orderFormData.district" class="block" @change="getKhoroos" placeholder="Дүүргээ сонгоно уу">
                          <el-option
                            v-for="(district, indexDistrict) in districts"
                            :key="indexDistrict"
                            :label="district.value"
                            :value="district.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Хороо" prop="khoroo">
                        <el-select v-model="orderFormData.khoroo" class="block" placeholder="Хороогоо сонгоно уу">
                          <el-option
                            v-for="(khoroo, indexKhoroo) in khoroos"
                            :key="indexKhoroo"
                            :label="khoroo.name"
                            :value="khoroo.name">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-form-item label="Байр" prop="bair_name">
                        <el-input placeholder="Байраа оруулна уу" v-model="orderFormData.bair_name"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Орц" prop="addr_orts">
                        <el-input placeholder="Орцны №" v-model="orderFormData.addr_orts"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <el-form-item label="Хаалга" prop="addr_haalga">
                        <el-input placeholder="Хаалганы №" v-model="orderFormData.addr_haalga"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Орцны код">
                        <el-input placeholder="Орцны код оруулах" v-model="orderFormData.addr_orts_code"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="Байршил">
                    <el-input v-model="orderFormData.location" disabled></el-input>
                  </el-form-item>
                  <div class="" style="width: 100%; height: 400px; display:block">
                    <gmap-map
                      :center="googleMap.center"
                      :zoom="12"
                      :options="googleMap.options"
                      :draggable="false"
                      style="width:100%;  height: 100%; margin-top: 5px;"
                    >
                    <gmap-marker
                      :position="googleMap.location"
                      :clickable="true"
                       @position_changed="changeLocation($event)"
                      :draggable="true"/>
                    </gmap-map>
                  </div>
                </div>
              </div>
              <div class="panel" v-if="activeStep === 2">
                <div class="panel-item">
                  <header>Бүтээгдэхүүн</header>
                  <el-input placeholder="Бүтээгдэхүүн хайх" class="input-with-select" v-model="searchText" @input="searchProduct">
                    <el-button
                      slot="prepend"
                      icon="el-icon-search"
                      @click="searchProduct"
                    ></el-button>
                  </el-input>
                </div>
              </div>
              <div class="panel" v-if="activeStep === 2">
                <div class="panel-item">
                  <header>Сагс</header>
                  <ul class="transfer-product-list" v-if="setProducts.length > 0">
                    <div v-for="(product, indexProduct) in setProducts" :key="indexProduct">
                      <div v-for="(variant, indexVariant) in product.variants" :key="indexVariant">
                        <el-row :gutter="5" type="flex" align="middle" class="mt10 pt10 border-top">
                          <el-col :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                            <div class="image-holder">
                              <img :src="variant.variant_image" alt="">
                            </div>
                          </el-col>
                          <el-col :xl="16" :lg="16" :md="14" :sm="14" :xs="14">
                            <div class="text-color-secondary">{{product.name_mon}}</div>
                            <div>{{variant.variant_name}}</div>
                            <div class="text-color-placeholder">{{variant.variant_sku}}</div>
                          </el-col>
                          <el-col :xl="16" :lg="16" :md="14" :sm="14" :xs="14">
                            <el-row :gutter="5" type="flex" align="middle">
                              <el-col :span="8" class="text-right">{{generateCurrency(mainPrices(variant).price)}} x </el-col>
                              <el-input
                                style="width:75px"
                                @keyup.enter.native="changeCount(variant)"
                                @focus="changeCount(variant)"
                                @blur="changeCount(variant)"
                                type="number"
                                :min="1"
                                size="mini"
                                v-model="variant.item_count"
                              />
                              <el-col :span="8" class="text-left"> = {{generateCurrency(mainPrices(variant).price * variant.item_count)}}</el-col>
                            </el-row>
                          </el-col>
                          <el-col :xl="4" :lg="4" :md="4" :sm="4" :xs="4">
                            <div class="text-right">
                              <i class="el-icon-close cursor-pointer" @click="deleteVariantFromOrder(product, variant)"></i>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </ul>
                  <div v-else class="ptb30 text-color-secondary text-center">Бүтээгдэхүүн сонгоно уу</div>
                </div>
                <div class="panel-item" v-if="setProducts.length > 0">
                  <header>Захиалгын дэлгэрэнгүй</header>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Захиалгын тэмдэглэл" class="mb20">
                        <el-input v-model="orderFormData.note" type="textarea" rows="3" placeholder="Тэмдэглэл бичих"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-row :gutter="10" class="mb20">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Барааны нийт үнэ
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>{{generateCurrency(generatePayTotal().variantTotalPrice)}}</div>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10" class="mb20">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            Хүргэлтийн төлбөр
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <el-input size="small" v-model="delivery_charge" type="number"/>
                        </el-col>
                      </el-row>
                      <el-row :gutter="10">
                        <el-col :span="12" class="text-right">
                          <div class="text-color-secondary">
                            <strong>Төлөх дүн</strong>
                          </div>
                        </el-col>
                        <el-col :span="12" class="text-right">
                          <div>
                            <strong>{{generateCurrency(generatePayTotal().variantTotalPrice + parseInt(delivery_charge))}}</strong>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="activeStep === 0 ? 0 : 8">
              <div class="panel" v-if="activeStep > 0">
                <div class="panel-item">
                  <header>Хэрэглэгч</header>
                  <ul class="pl20">
                    <li><b>Утас: </b>{{ orderFormData.user_phone }}</li>
                    <li><b>Email: </b>{{ orderFormData.user_email }}</li>
                  </ul>
                </div>
              </div>
              <div class="panel" v-if="activeStep > 1">
                <div class="panel-item">
                  <header>Хүргэлтийн хаяг</header>
                  <ul class="pl20">
                    <li><b>Хаяг: </b>{{ orderFormData.full_address }}</li>
                    <li><b>Орц: </b>{{ orderFormData.addr_orts }}</li>
                    <li><b>Хаалга: </b>{{ orderFormData.addr_haalga }}</li>
                    <li v-if="orderFormData.addr_orts_code !== ''"><b>Орцны код: </b>{{ orderFormData.addr_orts_code }}</li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="confirmLeaveForm = true"
              >Болих</el-button
            >
            <el-button v-if="activeStep > 0" type="warning" @click="prevStep" icon="el-icon-arrow-left">Өмнөх</el-button>
            <el-button v-if="activeStep < 2" :disabled="isButtonDisabled" :type="isButtonDisabled ? 'disabled' : 'primary'" :loading="isButtonLoading" @click="nextStep">Дараах <i class="el-icon-arrow-right el-icon-right"></i></el-button>
            <el-button v-if="activeStep===2" :disabled="isDisabledCart" :type="isDisabledCart ? 'disabled' : 'success'" :loading="isButtonLoading" @click="saveOrder()">Захиалах</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!-- Product search -->
    <el-dialog
      title="Бүтээгдэхүүн сонгох"
      :visible.sync="visibleProductDialog"
      width="500"
    >
      <div>
        <el-input
          placeholder="Барааны нэр, SKU ..."
          v-model="searchText"
          @input="searchProduct"
        >
          <el-button slot="prepend" icon="el-icon-search" @click="searchProduct"></el-button>
        </el-input>
      </div>
      <div class="mt20" v-loading="productLoading">
        <ul class="transfer-product-list" v-if="products.length > 0">
          <li v-for="product in products" :key="product.productId">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="product.image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.name_mon}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :disabled="isProductDisabled(product)" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="variant in product.variants" :key="variant.variant_id">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="16" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                    <div class="text-color-secondary">
                      {{variant.variant_sku}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    {{generateCurrency(variant.variant_sellPrice)}}
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.availableCount}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox v-model="variant.checked" :disabled="variant.availableCount === 0 && product.sell_check === false" @change="checkVariant(product, variant)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
        <div v-else class="text-center mtb30">
          <div class="text-color-placeholder">
            <p>
              Бараа байхгүй байна
              <router-link to="/add-product">
                бүтээгдэхүүн нэмэх
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleProductDialog = false">Болих</el-button>
        <el-button type="success" @click="setOrderProducts">
          Бүтээгдэхүүн нэмэх
        </el-button>
      </span>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/order">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>
<script>
import helper from '../../../helpers/helper'
import dayjs from 'dayjs'
import CustomHeader from '../../../components/common/customHeader'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import Loader from '../../../components/common/loader'
import services from '../../../helpers/services'
export default {
  name: 'addOrder',
  components: {
    CustomHeader,
    Loader
  },
  data () {
    return {
      isLoadingEbarimt: false,
      khoroos: [],
      districts: [],
      setProducts: [],
      delivery_charge: 5000,
      totalPrice: 0,
      nowDate: '',
      orderFormData: {
        khoroo: '',
        district: '',
        note: '',
        vat_regno: '',
        vat_company_name: '',
        full_address: '',
        bair_name: '',
        addr_orts: '',
        addr_haalga: '',
        addr_orts_code: '',
        user_lat: '',
        user_lon: '',
        user_phone: '',
        user_keys: '',
        user_email: '',
        menu_total: 0,
        discount_total: 0,
        pay_total: 0,
        pay_total_no_discount: 0,
        un_official_address: '',
        vat_type: 1,
        location: '47.9174, 106.9137',
        order_items: []
      },
      menu_price: '',
      nosale_price: '',
      item_count: 1,
      sku: '',
      main_category_id: '',
      variant_name: '',
      product_name: '',
      supplier_name: '',
      supplier_id: '',
      brand_id: '',
      brand_name: '',
      product_id: '',
      variant_id: '',
      fulfillment: false,
      warehouses: [],
      activeStep: 0,
      confirmLeaveForm: false,
      visibleProductDialog: false,
      productLoading: false,
      addressLoading: false,
      isButtonLoading: false,
      isDiscountLoading: false,
      addressQuery: '',
      backupCartData: [],
      orderForm: {
        address: {
          desc: '',
          full_address: '',
          haalga: '',
          orts: '',
          orts_code: ''
        },
        products: [],
        phone: '',
        email: '',
        discount_code: '',
        menu_total: 0,
        delivery_charge: 0,
        pay_total: 0,
        note: ''
      },
      vatTypes: [{
        value: 1,
        label: 'Хувь хүн'
      }, {
        value: 3,
        label: 'Байгууллага'
      }],
      orderFormRules: {
        vat_regno: [
          { required: true, message: 'Байгууллагын регистрээ оруулна уу?', trigger: 'blur' }
        ],
        vat_type: [
          { required: true, message: 'Харилцагчийн төрлөө сонгоно уу?', trigger: 'blur' }
        ],
        user_phone: [
          { required: true, message: 'Хэрэглэгчийн утасны дугаар оруулна уу', trigger: 'blur' }
        ],
        user_email: [
          { required: true, message: 'Хэрэглэгчийн мэйл хаягийг оруулна уу', trigger: 'blur' },
          { type: 'email', message: 'Email формат буруу байна', trigger: ['blur', 'change'] }
        ],
        full_address: [
          { required: true, message: 'Хэрэглэгчийн хаягийг сонгон уу', trigger: ['blur', 'change'] }
        ],
        addr_orts: [
          { required: true, message: 'Орцны мэдээлэл оруулна уу', trigger: 'blur' }
        ],
        addr_haalga: [
          { required: true, message: 'Хаалганы дугаар оруулна уу', trigger: 'blur' }
        ],
        bair_name: [
          { required: true, message: 'Байраа оруулна уу?', trigger: 'blur' }
        ],
        district: [
          { required: true, message: 'Дүүргээ оруулна уу?', trigger: 'blur' }
        ],
        khoroo: [
          { required: true, message: 'Хороогоо оруулна уу?', trigger: 'blur' }
        ]
      },
      searchText: '',
      products: [],
      userId: null,
      suggestedAddresses: [],
      isLoading: false,

      googleMap: {
        center: {
          lat: 47.9174,
          lng: 106.9137
        },

        location: {
          lat: 47.9174,
          lng: 106.9137
        },

        markerOptions: {
          size: { width: 100, height: 90, f: 'px', b: 'px' },
          scaledSize: { width: 45, height: 45, f: 'px', b: 'px' }
        },

        options: {
          scrollwheel: true,
          mapTypeControl: true,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 20,
          gestureHandling: 'greedy'
        }
      }
    }
  },
  created () {
    this.getDistrict()
  },
  computed: {
    isButtonDisabled () {
      var isDisabled = false
      if (this.activeStep === 0 && this.isDisabledUser) {
        isDisabled = true
      } else if (this.activeStep === 1 && this.isDisabledAddress) {
        isDisabled = true
      }
      return isDisabled
    },
    isDisabledCart () {
      return this.setProducts.length === 0
    },
    isDisabledUser () {
      var isUserDisabled = false
      if (this.orderFormData.user_phone.length < 6 || this.orderFormData.user_phone.length > 10) {
        isUserDisabled = true
      }
      if (!this.isEmail(this.orderFormData.user_email)) {
        isUserDisabled = true
      }
      return isUserDisabled
    },
    isDisabledCoupon () {
      return this.orderForm.discount_code === ''
    },
    isDisabledAddress () {
      var isAddressDisabled = false
      if (this.orderFormData.addr_haalga === '') {
        isAddressDisabled = true
      }
      if (this.orderFormData.bair_name === '') {
        isAddressDisabled = true
      }
      if (this.orderFormData.district === '') {
        isAddressDisabled = true
      }
      if (this.orderFormData.addr_orts === '') {
        isAddressDisabled = true
      }
      return isAddressDisabled
    }
  },
  methods: {
    getDistrict () {
      services.getDistrict().then((response) => {
        this.districts = response
      })
    },
    getKhoroos (data) {
      this.orderFormData.full_address = ''
      this.orderFormData.user_lat = ''
      this.orderFormData.user_lon = ''
      const query = '?district=' + data
      services.getKhoroos(query).then((response) => {
        this.khoroos = response
      })
    },
    numberValidate () {
      if (this.orderFormData.vat_regno.length <= 10) {
        this.checkEbarimt()
      }
    },
    checkEbarimt () {
      this.isLoadingEbarimt = true
      services.checkEbarimt(this.orderFormData.vat_regno).then(response => {
        if (response.found === true) {
          this.isLoadingEbarimt = false
          this.orderFormData.vat_company_name = response.name
        } else {
          this.isLoadingEbarimt = false
        }
      })
    },
    mainPrices (variant) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.nowDate = dayjs().format('YYYY-MM-DD HH:mm:ss')
      const ans = {
        price: variant.variant_sellPrice
      }
      let startDate = null
      let endDate = null
      if (variant.start_date_sale) {
        startDate = dayjs(variant.start_date_sale)
      }
      if (variant.end_date_sale) {
        endDate = dayjs(variant.end_date_sale)
      }
      if (!startDate && endDate) {
        if (endDate > this.nowDate) {
          ans.price = variant.variant_sale_price
        }
      }
      if (startDate !== null && endDate !== null) {
        if (startDate < dayjs() && endDate > dayjs()) {
          ans.price = variant.variant_sale_price
        } else if (startDate > dayjs() && endDate < dayjs()) {
          ans.price = variant.variant_sellPrice
        }
      } else if (
        startDate === null &&
        endDate === null &&
        variant.variant_sale_price > 0
      ) {
        ans.price = variant.variant_sale_price
      }
      if (
        variant.variant_sellPrice !== variant.variant_sale_price &&
        startDate === null &&
        endDate === null
      ) {
        ans.price = variant.variant_sale_price
      }
      return ans
    },

    generatePayTotal () {
      const ans = {
        variantTotalPrice: 0,
        pay_total_no_discount: 0
      }
      this.setProducts.forEach(product => {
        product.variants.forEach(variant => {
          ans.variantTotalPrice = ans.variantTotalPrice + this.mainPrices(variant).price * variant.item_count
          ans.pay_total_no_discount = ans.pay_total_no_discount + variant.variant_sellPrice * variant.item_count
        })
      })
      this.orderFormData.menu_total = parseInt(ans.variantTotalPrice)
      this.orderFormData.pay_total = ans.variantTotalPrice + parseInt(this.delivery_charge)
      this.orderFormData.pay_total_no_discount = ans.pay_total_no_discount
      return ans
    },

    changeLocation (event) {
      this.orderFormData.location = event.lat() + ', ' + event.lng()
      this.orderFormData.user_lat = event.lat()
      this.orderFormData.user_lon = event.lng()
    },

    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    generateCouponType (type) {
      var response = null
      switch (type) {
        case 'PERCENTAGE':
          response = 'Хувиар хямдрах'
          break
        case 'PRODUCT':
          response = 'Нэмэлт бүтээгдэхүүн'
          break
        case 'FIXED_AMOUNT':
          response = ' Мөнгөн дүнгээр хямдрах'
          break
        default:
          response = 'Хямдрал'
          break
      }
      return response
    },
    prevStep () {
      this.activeStep--
    },
    nextStep () {
      if (this.activeStep === 0) {
        this.isButtonLoading = true
        if (this.orderFormData.user_phone !== '' && this.orderFormData.user_email !== '') {
          this.isButtonLoading = false
          this.activeStep++
        } else {
          this.isButtonLoading = true
        }
      } else if (this.activeStep === 1) {
        const tempLatLon = this.orderFormData.location.split(', ')
        this.orderFormData.user_lat = tempLatLon[0]
        this.orderFormData.user_lon = tempLatLon[1]
        if (this.orderFormData.full_address === '') {
          this.orderFormData.full_address = this.orderFormData.full_address.concat(this.orderFormData.district, ' ', this.orderFormData.khoroo, ' ', this.orderFormData.bair_name + '-р байр')
        }
        this.isButtonLoading = true
        if (this.orderFormData.addr_orts !== '' && this.orderFormData.addr_haalga !== '') {
          this.isButtonLoading = false
          this.activeStep++
        } else {
          this.isButtonLoading = true
        }
      }
    },
    isEmail (email) {
      // eslint-disable-next-line
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    async createFingerprint () {
      const fp = await FingerprintJS.load()
      const result = await fp.get().then((result) => { return result.visitorId })
      return result
    },
    onChangeAddress (data) {
      const tempLatLon = this.orderFormData.location.split(', ')
      if (this.suggestedAddresses.length > 0) {
        this.suggestedAddresses.forEach((element) => {
          if (element.full_address === data) {
            this.orderFormData.bair_name = element.bairname ? element.bairname : this.orderFormData.bair_name
            this.orderFormData.user_lat = element.latitude ? element.latitude : this.orderFormData.user_lat === '' ? this.orderFormData.user_lat = tempLatLon[0] : ''
            this.orderFormData.user_lon = element.longitude ? element.longitude : this.orderFormData.user_lon === '' ? this.orderFormData.user_lon = tempLatLon[1] : ''
            this.orderFormData.un_official_address = element.un_official_address ? element.un_official_address : this.orderFormData.un_official_address
            this.orderFormData.district = element.district ? element.district : this.orderFormData.district
            this.orderFormData.khoroo = element.horoo ? element.horoo : this.orderFormData.khoroo
          }
        })
      } else {
        this.orderFormData.user_lat = tempLatLon[0]
        this.orderFormData.user_lon = tempLatLon[1]
      }
    },
    changeCount (variant) {
      this.orderFormData.order_items.forEach(element => {
        if (element.variant_id === variant.variant_id) {
          // eslint-disable-next-line no-return-assign
          element.item_count = parseInt(variant.item_count)
        }
      })
    },
    saveOrder () {
      this.isLoading = true
      const body = this.orderFormData
      services.createOrder(body).then((response) => {
        this.isLoading = false
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', response.message, 'success')
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000)
        } else {
          this.alertReporter('Уучлаарай', response.message, 'error')
        }
      }).catch((error) => {
        this.isLoading = false
        this.alertReporter('Уучлаарай', error.message, 'error')
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    isProductDisabled (product) {
      var isCheckable = false
      if (product.sell_check === true) {
        isCheckable = false
      } else if (product.sell_check === false) {
        var count = 0
        product.variants.forEach(variant => {
          count = count + variant.availableCount
        })
        isCheckable = count === 0
      } else {
        isCheckable = true
      }
      return isCheckable
    },
    checkProduct (product) {
      product.variants.forEach(variant => {
        var isCheckable = product.checked
        // eslint-disable-next-line no-mixed-operators
        if (product.sell_check === true && isCheckable === true || variant.availableCount > 0 && isCheckable === true) {
          isCheckable = true
        } else {
          isCheckable = false
        }
        variant.checked = isCheckable
      })
    },
    checkVariant (product, variant) {
      let checkedCount = 0
      product.variants.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variants.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variants.length
    },
    deleteVariantFromOrder (product, delVariant) {
      product.variants = product.variants.filter(variant => variant !== delVariant)
    },
    generateCartItem (tempProducts) {
      const tempData = JSON.parse(JSON.stringify(tempProducts))
      var tempItems = []
      tempData.forEach(element => {
        element.variants.forEach(secondElement => {
          const orderItem = {
            brand_id: element.brand_id,
            brand_name: element.brand_name_mon,
            item_count: secondElement.item_count,
            menu_price: secondElement.variant_sellPrice,
            nosale_price: secondElement.variant_sellPrice,
            product_id: element.productId,
            product_name: element.name_mon,
            sku: element.sku,
            supplier_id: element.supplier_id,
            supplier_name: element.supplier_name_mon,
            variant_id: secondElement.variant_id,
            variant_name: secondElement.variant_name,
            sell_check: element.sell_check !== undefined ? element.sell_check : false,
            availableCount: secondElement.availableCount,
            categories: element.category_id,
            main_category_id: element.main_category_id ? element.main_category_id : '',
            fulfillment: true,
            supplier_sku: element.supplier_sku ? element.supplier_sku : '',
            register_name: element.register_name ? element.register_name : '',
            warehouses: secondElement.warehouse,
            variant_image: Array.isArray(secondElement.variant_image) ? secondElement.variant_image[0] : secondElement.variant_image,
            company_regno: element.supplier_regno ? element.supplier_regno : '',
            brand_fee_percent: element.brand_fee ? element.brand_fee : 0,
            supplier_ebarimt_type: element.ebarimt_type ? element.ebarimt_type : ''
          }
          tempItems.push(orderItem)
        })
      })
      return tempItems
    },
    setOrderProducts () {
      // const tempProducts = []
      this.products.forEach(product => {
        const variants = [...product.variants.filter(variant => variant.checked)]
        if (variants.length > 0) {
          product.variants = variants
          this.setProducts.push(product)
        }
      })
      if (this.setProducts.length > 0) {
        this.visibleProductDialog = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Бүтээгдэхүүн сонгоно уу',
          duration: 2000
        })
      }
      if (this.setProducts.length > 0) {
        this.orderFormData.order_items = this.generateCartItem(this.setProducts)
        this.visibleProductDialog = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Бүтээгдэхүүн сонгоно уу',
          duration: 2000
        })
      }
    },
    searchProduct () {
      if (this.searchText.length > 1) {
        this.productLoading = true
        this.visibleProductDialog = true
        services.getProductsWithVariant(this.searchText).then(response => {
          if (Array.isArray(response) && response.length > 0) {
            response.forEach(product => {
              const property = {
                checked: false,
                isIndeterminate: false
              }
              Object.assign(product, property, product)
              product.variants.forEach(variant => {
                const property = {
                  checked: false,
                  item_count: 1
                }
                Object.assign(variant, property, variant)
              })
            })
            this.products = response
          }
          this.productLoading = false
        })
      }
    },
    filterAddress (val) {
      if (val.length > 1) {
        services.searchAddress(val).then(response => {
          if (Array.isArray(response) && response.length > 0) {
            this.suggestedAddresses = response
          } else {
            this.suggestedAddresses = []
          }
        })
      }
    }
  }
}
</script>
